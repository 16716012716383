<template>
  <div class="container" v-loading="loading" id="container">
    <div class="top" id="top">
      <p><i class="el-icon-tickets" style="margin-right:5px"></i>基本信息</p>
      <el-descriptions class="descriptionsClass" :column="4"  border :contentStyle="contentStyle">
        <el-descriptions-item v-for="(i,index) in descriptionsForm" :key="i.description" :label="i.description" labelStyle="width: 150px;background: rgba(25,140,255,.08);">{{i.value}}</el-descriptions-item>
      </el-descriptions>
    </div>

    <div class="bottom" :style="{height:bottomHeight+'px'}" id="bottom">
      <el-tabs v-model="activeName" @tab-click="handleClickTab">
        <el-tab-pane label="仪表信息" name="ybxx">
          <el-row  class="ybxx_box">
            <el-col :span="8">
              <div class="ybxx_box_left">
                <el-descriptions class="descriptionsClass" :column="1"  border>
                  <el-descriptions-item v-for="(i,index) in ybxxDescriptionsForm" :key="i.description" :label="i.description" labelStyle="width: 150px;background: rgba(25,140,255,.08);">{{i.value}}</el-descriptions-item>
                </el-descriptions>
              </div>
            </el-col>
            <el-col :span="8">
              <el-row class="ybxx_box_mid">
                <el-col :span="12" style="position: relative;padding: 10px;" id="yibiaoimg">
                  <el-image style="margin-top: 20px;" :src="shuibiaoImgUrl"></el-image>
                  <el-image class="dynamic" :src="shuibiao1ImgUrl"></el-image>
                  <span class="ybxxImgReading" :style="{width:readingWidth+'px'}">{{ybxxImgReading}}</span>
                </el-col>
                <el-col :span="12" style="padding-top: 30px;">
                  <p v-for="(i,index) in ybxxImgForm">{{i.description}}  {{i.value}}</p>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="8">
              <div class="ybxx_box_right">
                <div class="app-container flex-item" id="txdituContainer"></div>
              </div>
            </el-col>
          </el-row>

        </el-tab-pane>
        <el-tab-pane label="抄表历史" name="cbls">
          <base-search :searchList="$ybzlCblsSearch" :noOperation="true" :listQuery='listQuery' v-on:search='getList' style="display: inline-block;"></base-search>
          <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" :tableTotal="tableTotal" :hasPermissionBtn="false" ></basic-table >
        </el-tab-pane>
        <el-tab-pane label="日用量分析" name="rylfx">
          <base-search :searchList="$ybzlCblsSearch" :noOperation="true" :listQuery='listQuery' v-on:search='getList' style="display: inline-block;"></base-search>
          <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" :tableTotal="tableTotal" :hasPermissionBtn="false" ></basic-table >
        </el-tab-pane>
        <!-- <el-tab-pane label="日冻结明细" name="rdjmx">
          <base-search :searchList="$ybzlCblsSearch" :noOperation="true" :listQuery='listQuery' v-on:search='getList' style="display: inline-block;"></base-search>
          <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" :tableTotal="tableTotal" :hasPermissionBtn="false" ></basic-table >
        </el-tab-pane> -->
        <el-tab-pane label="控制记录" name="kzjl">
          <base-search :searchList="$ybzlCblsSearch" :noOperation="true" :listQuery='listQuery' v-on:search='getList' style="display: inline-block;"></base-search>
          <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" :tableTotal="tableTotal" :hasPermissionBtn="false" ></basic-table >
        </el-tab-pane>
        <el-tab-pane label="告警信息" name="gjxx">
          <base-search :searchList="$ybzlCblsSearch" :noOperation="true" :listQuery='listQuery' v-on:search='getList' style="display: inline-block;"></base-search>
          <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" :tableTotal="tableTotal" :hasPermissionBtn="false" ></basic-table >
        </el-tab-pane>
        <el-tab-pane label="变更记录" name="bgjl">
          <base-search :searchList="$ybzlCblsSearch" :noOperation="true" :listQuery='listQuery' v-on:search='getList' style="display: inline-block;"></base-search>
          <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" :tableTotal="tableTotal" :hasPermissionBtn="false" ></basic-table >
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  import shuibiaoImgUrl from '@/assets/img/shuibiao.png'
  import shuibiao1ImgUrl from '@/assets/img/shuibiao1.png'
  import iconImg from '@/assets/img/Water.png'
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        contentStyle:{
          'min-width': '100px',   //最小宽度
        },
        readingWidth:'20',
        loading:false,//遮罩
        activeName:'ybxx',//当前选中标签
        shuibiaoImgUrl:shuibiaoImgUrl,//大表图片
        shuibiao1ImgUrl:shuibiao1ImgUrl,//转动图片
        bottomHeight:200,//底部高度
        tipDatas:{},//基础数据
        descriptionsForm:[//基本信息描述列表
          {description:'用户编号',value:'',itemKey:'userNumber'},
          {description:'用户姓名',value:'',itemKey:'userName'},
          {description:'缴费编号',value:'',itemKey:'payNumber'},
          {description:'手机号码',value:'',itemKey:'mobilePhoneNum'},
          {description:'用户类型',value:'',itemKey:'userType'},
          {description:'用水人数',value:'',itemKey:'useWaterUserCount'},
          {description:'用户状态',value:'',itemKey:'userState'},
          {description:'所属机构',value:'',itemKey:'organization'},
          {description:'身份证号',value:'',itemKey:'idCard'},
          {description:'充值时间',value:'',itemKey:'rechargeTime'},
          {description:'账户余额',value:'',itemKey:'balance'},
          {description:'用户地址',value:'',itemKey:'address'},
          {description:'所属区域',value:'',itemKey:'areaName'},
          {description:'用水性质',value:'',itemKey:'userWaterNature'},
          
        ],
        ybxxDescriptionsForm:[//仪表信息描述列表
          {description:'表名称',value:'',itemKey:'surfaceName'},
          {description:'表编号',value:'',itemKey:'surfaceNum'},
          {description:'表地址',value:'',itemKey:'surfaceAddress'},
          {description:'IMEI',value:'',itemKey:'imei'},
          {description:'类型规格',value:'',itemKey:'surfaceType'},
          {description:'安装地址',value:'',itemKey:'installAddress'},
          {description:'阀门状态',value:'',itemKey:'valveState'},
        ],
        ybxxImgForm:[//图片数据
          {description:'最新读数',value:'',itemKey:'currentReading'},
          {description:'抄表时间',value:'',itemKey:'lastReadTime'},
          {description:'IMEI编号',value:'',itemKey:'imei'},
          {description:'IMSI编号',value:'',itemKey:'imsi'},
          {description:'电池电压',value:'',itemKey:'battery_voltage'},
          {description:'信号强度',value:'',itemKey:'signal_intensity'},
        ],
        ybxxImgReading:85,//当前读数
        iconImgUrl:iconImg,//地图图标
        listQuery: { // 查询条件
          page: 1,
          limit: 20,
          queryType: '0',
          queryKey:'SurfaceNum',
          queryValue: '110111160150017858',
          TreeIds:[],
          beginTime:'2023-05-05',
          endTime:'2023-05-12',
          dateScope:[new Date(new Date().getTime() - 3600 * 1000 * 24 * 7), new Date()],
          NewSurfaceNum:'',//换表记录用于查询新表编号
          abnormalState:"-1",//告警记录用
          processingStatus:"-1",//告警记录用
        },
        tableHeaderList:this.$store.state.headerList,
        firstHeaderList:[],// 主列表列定义
        tableData:[],
        tableHeight:500,//表格高度
        tableTotal:0,//明细条数

      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      if(this.$route.query.surfaceNum){//如果是页面跳转
        this.listQuery.queryValue = this.listQuery.NewSurfaceNum = this.$route.query.surfaceNum//表编号
      }
    },
    mounted() {
      this.getList()
      // 计算bottom高度
      this.$nextTick(()=>{
        //获取图片宽度-设置读数宽度
        var yibiaoimg = document.getElementById("yibiaoimg");
        var yibiaoimgW = yibiaoimg.offsetWidth
        this.readingWidth = yibiaoimgW/2
        //获取图片宽度-设置读数宽度
        let container=document.getElementById("container");
        let top=document.getElementById("top");
        this.bottomHeight=container.offsetHeight-top.offsetHeight-10
        this.tableHeight = this.bottomHeight -180
        window.addEventListener('resize', () => { 
          //获取图片宽度-设置读数宽度
          var yibiaoimg = document.getElementById("yibiaoimg");
          var yibiaoimgW = yibiaoimg.offsetWidth
          this.readingWidth = yibiaoimgW/2
          //获取图片宽度-设置读数宽度
          let container=document.getElementById("container");
          let top=document.getElementById("top");
          this.bottomHeight=container.offsetHeight-top.offsetHeight-10
          this.tableHeight = this.bottomHeight -180
          this.$forceUpdate()
        });
        // 计算table高度
      })
    },
    methods: {
      //切换标签页
      handleClickTab(tab){
        console.log(tab.name, tab.label);
        this.activeName = tab.name
        this.getList()
      },
      //获取数据
      getList(){
        if(this.activeName == 'ybxx'){//仪表信息
          this.getYbxxData();//获取仪表信息数据
        }
        if(this.activeName == 'cbls'){//抄表历史
          this.getCblsData();//获取抄表历史数据
        }
        if(this.activeName == 'rylfx'){//日用量分析
          this.getRylfxData();//获取日用量分析数据
        }
        if(this.activeName == 'rdjmx'){//日冻结明细
          this.getRdjmxData();//获取日冻结明细数据
        }
        if(this.activeName == 'kzjl'){//控制记录
          this.getKzjlData();//获取控制记录数据
        }
        if(this.activeName == 'gjxx'){//告警信息
          this.getGjxxData();//获取告警信息数据
        }
        if(this.activeName == 'bgjl'){//变更记录
          this.getBgjlData();//获取变更记录数据
        }
      },
      //获取仪表信息数据
      getYbxxData(){
        basicDataApi.ybzlGetBasicData(this.listQuery).then(response => {
          if(response.code == 200){
            this.tipDatas = response.result
            this.descriptionsForm.forEach(i=>{
              i.value = this.tipDatas[i.itemKey]
            })
            this.ybxxDescriptionsForm.forEach(i=>{
              i.value = this.tipDatas[i.itemKey]
            })
            this.ybxxImgForm.forEach(i=>{
              i.value = this.tipDatas[i.itemKey]
            })
            this.ybxxImgReading = this.tipDatas.currentReading?parseInt(this.tipDatas.currentReading).toFixed():0//当前读数
            this.initMap()//初始化地图
          }
        }).catch(()=>{
          
        })
      },
      //初始化地图
      initMap(){
        var center = new TMap.LatLng(this.tipDatas.latitude,this.tipDatas.longitude);//设置中心点坐标

        // 初始化地图
        var map = new TMap.Map(txdituContainer, {
          zoom: 17, // 设置地图缩放
          center: center, // 设置地图中心点坐标，
          pitch: 0, // 俯仰度
          rotation: 0, // 旋转角度
        });

        // MultiMarker文档地址：https://lbs.qq.com/webApi/javascriptGL/glDoc/glDocMarker
        var marker = new TMap.MultiMarker({
          map: map,
          styles: {
            //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
            "myStyle": new TMap.MarkerStyle({ 
              "width": 35,  // 点标记样式宽度（像素）
              "height": 35, // 点标记样式高度（像素）
              "src": this.iconImgUrl,  //图片路径
              //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
              "anchor": { x: 16, y: 32 },
              direction: 'bottom', // 标注点文本文字相对于标注点图片的方位
              offset: { x: 0, y: 8 }, // 标注点文本文字基于direction方位的偏移属性
            }) 
          },
          geometries: [
            // 点标记数据数组
            {
              // 标记位置(纬度，经度，高度)
              position: center,
              id: 'marker',
              styleId :'myStyle'
            },
          ],
        });
        return
      },
      //获取抄表历史数据
      getCblsData(){
        this.loading = true
        if(this.listQuery.dateScope){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        }
        basicDataApi.cbjlGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              i.columnWidth = 160
            })
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      //获取日用量分析数据
      getRylfxData(){
        this.loading = true
        if(this.listQuery.dateScope){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        }
        basicDataApi.ybzlGetRylData(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              i.columnWidth = 160
            })
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      //获取日冻结明细数据
      getRdjmxData(){

      },
      //获取控制记录数据
      getKzjlData(){
        this.loading = true
        if(this.listQuery.dateScope){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        }
        basicDataApi.kzjlGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              i.columnWidth = 160
            })
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      //获取告警信息数据
      getGjxxData(){
        this.loading = true
        if(this.listQuery.dateScope){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        }
        basicDataApi.gjjlGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              i.columnWidth = 160
            })
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      //获取变更记录数据
      getBgjlData(){
        this.loading = true
        if(this.listQuery.dateScope){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        }
        basicDataApi.hbjlGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              i.columnWidth = 160
            })
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      

      // 切换分页
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
    },
  }

</script>

<style lang="scss" scoped>
  .container{
    height: calc(100% - 20px );
    padding: 10px;
    .top{
      padding: 10px;
      background: #fff;
      p{margin: 0;padding: 10px;}
      .descriptionsClass{margin: 10px;}
    }
    .bottom{
      margin-top: 10px;
      padding: 10px;
      background: #fff;
      .ybxx_box{//仪表信息
        .ybxx_box_left,.ybxx_box_mid,.ybxx_box_right{border: 1px solid #EBEEF5;height: 325px; background: #fff;}
        .ybxx_box_mid{
          .dynamic{
            width: 50px;
            height: 50px;
            position: absolute;
            left: 116px;
            top: 153px;
            animation: rotate360 2.5s linear infinite;
          }
          .ybxxImgReading{
            position: absolute;
            left: 65px;
            top: 78px;
            font-size: 28px;
            display: inline-block;
            text-align: center;
          }
        }
        .ybxx_box_right{padding: 10px 10px 0 10px;}
      }
    }
  }
</style>
<style>
@keyframes rotate360 {
  100% {
    transform: rotate(360deg);
  }
}
</style>
