var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "container",
      attrs: { id: "container" },
    },
    [
      _c(
        "div",
        { staticClass: "top", attrs: { id: "top" } },
        [
          _vm._m(0),
          _c(
            "el-descriptions",
            {
              staticClass: "descriptionsClass",
              attrs: { column: 4, border: "", contentStyle: _vm.contentStyle },
            },
            _vm._l(_vm.descriptionsForm, function (i, index) {
              return _c(
                "el-descriptions-item",
                {
                  key: i.description,
                  attrs: {
                    label: i.description,
                    labelStyle:
                      "width: 150px;background: rgba(25,140,255,.08);",
                  },
                },
                [_vm._v(_vm._s(i.value))]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "bottom",
          style: { height: _vm.bottomHeight + "px" },
          attrs: { id: "bottom" },
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClickTab },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "仪表信息", name: "ybxx" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "ybxx_box" },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "ybxx_box_left" },
                          [
                            _c(
                              "el-descriptions",
                              {
                                staticClass: "descriptionsClass",
                                attrs: { column: 1, border: "" },
                              },
                              _vm._l(
                                _vm.ybxxDescriptionsForm,
                                function (i, index) {
                                  return _c(
                                    "el-descriptions-item",
                                    {
                                      key: i.description,
                                      attrs: {
                                        label: i.description,
                                        labelStyle:
                                          "width: 150px;background: rgba(25,140,255,.08);",
                                      },
                                    },
                                    [_vm._v(_vm._s(i.value))]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-row",
                            { staticClass: "ybxx_box_mid" },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    padding: "10px",
                                  },
                                  attrs: { span: 12, id: "yibiaoimg" },
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: { "margin-top": "20px" },
                                    attrs: { src: _vm.shuibiaoImgUrl },
                                  }),
                                  _c("el-image", {
                                    staticClass: "dynamic",
                                    attrs: { src: _vm.shuibiao1ImgUrl },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ybxxImgReading",
                                      style: { width: _vm.readingWidth + "px" },
                                    },
                                    [_vm._v(_vm._s(_vm.ybxxImgReading))]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "padding-top": "30px" },
                                  attrs: { span: 12 },
                                },
                                _vm._l(_vm.ybxxImgForm, function (i, index) {
                                  return _c("p", [
                                    _vm._v(
                                      _vm._s(i.description) +
                                        " " +
                                        _vm._s(i.value)
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "ybxx_box_right" }, [
                          _c("div", {
                            staticClass: "app-container flex-item",
                            attrs: { id: "txdituContainer" },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "抄表历史", name: "cbls" } },
                [
                  _c("base-search", {
                    staticStyle: { display: "inline-block" },
                    attrs: {
                      searchList: _vm.$ybzlCblsSearch,
                      noOperation: true,
                      listQuery: _vm.listQuery,
                    },
                    on: { search: _vm.getList },
                  }),
                  _c("basic-table", {
                    attrs: {
                      tableHeight: _vm.tableHeight,
                      tableHeaderList: _vm.firstHeaderList,
                      tableData: _vm.tableData,
                      listQuery: _vm.listQuery,
                      tableTotal: _vm.tableTotal,
                      hasPermissionBtn: false,
                    },
                    on: { pagination: _vm.handleCurrentChange },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "日用量分析", name: "rylfx" } },
                [
                  _c("base-search", {
                    staticStyle: { display: "inline-block" },
                    attrs: {
                      searchList: _vm.$ybzlCblsSearch,
                      noOperation: true,
                      listQuery: _vm.listQuery,
                    },
                    on: { search: _vm.getList },
                  }),
                  _c("basic-table", {
                    attrs: {
                      tableHeight: _vm.tableHeight,
                      tableHeaderList: _vm.firstHeaderList,
                      tableData: _vm.tableData,
                      listQuery: _vm.listQuery,
                      tableTotal: _vm.tableTotal,
                      hasPermissionBtn: false,
                    },
                    on: { pagination: _vm.handleCurrentChange },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "控制记录", name: "kzjl" } },
                [
                  _c("base-search", {
                    staticStyle: { display: "inline-block" },
                    attrs: {
                      searchList: _vm.$ybzlCblsSearch,
                      noOperation: true,
                      listQuery: _vm.listQuery,
                    },
                    on: { search: _vm.getList },
                  }),
                  _c("basic-table", {
                    attrs: {
                      tableHeight: _vm.tableHeight,
                      tableHeaderList: _vm.firstHeaderList,
                      tableData: _vm.tableData,
                      listQuery: _vm.listQuery,
                      tableTotal: _vm.tableTotal,
                      hasPermissionBtn: false,
                    },
                    on: { pagination: _vm.handleCurrentChange },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "告警信息", name: "gjxx" } },
                [
                  _c("base-search", {
                    staticStyle: { display: "inline-block" },
                    attrs: {
                      searchList: _vm.$ybzlCblsSearch,
                      noOperation: true,
                      listQuery: _vm.listQuery,
                    },
                    on: { search: _vm.getList },
                  }),
                  _c("basic-table", {
                    attrs: {
                      tableHeight: _vm.tableHeight,
                      tableHeaderList: _vm.firstHeaderList,
                      tableData: _vm.tableData,
                      listQuery: _vm.listQuery,
                      tableTotal: _vm.tableTotal,
                      hasPermissionBtn: false,
                    },
                    on: { pagination: _vm.handleCurrentChange },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "变更记录", name: "bgjl" } },
                [
                  _c("base-search", {
                    staticStyle: { display: "inline-block" },
                    attrs: {
                      searchList: _vm.$ybzlCblsSearch,
                      noOperation: true,
                      listQuery: _vm.listQuery,
                    },
                    on: { search: _vm.getList },
                  }),
                  _c("basic-table", {
                    attrs: {
                      tableHeight: _vm.tableHeight,
                      tableHeaderList: _vm.firstHeaderList,
                      tableData: _vm.tableData,
                      listQuery: _vm.listQuery,
                      tableTotal: _vm.tableTotal,
                      hasPermissionBtn: false,
                    },
                    on: { pagination: _vm.handleCurrentChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", {
        staticClass: "el-icon-tickets",
        staticStyle: { "margin-right": "5px" },
      }),
      _vm._v("基本信息"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }